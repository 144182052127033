<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-3">
        <h3 class="text-secondary">Category</h3>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link bg-primary text-white" aria-current="page" href="#">
              Common <span class="badge bg-secondary">4</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Internal news</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Social media news</a>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <h3 class="text-secondary">Blogs list</h3>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog10' }">
                <img
                  src="/imgc/blog/10/diwali-homepage.webp"
                  alt="How you can celebrate festive seasons like never before"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog10' }" class="link-primary">
                  <h5 class="card-title">
                    How you can celebrate festive seasons like never before
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  Our generation has seen a lot of changes in their lifestyle, especially in how we
                  celebrate festivals like Diwali! From spending time with friends and family, to
                  discovering new things such as redecorating our houses or travelling around India;
                  it’s important that we understand these changes as well as how they made us the
                  way we are!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog9' }">
                <img
                  src="/imgc/blog/9/header-homepage.jpg"
                  alt="Flight companion for elderly"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog9' }" class="link-primary">
                  <h5 class="card-title">Flight companion for elderly</h5>
                </router-link>
                <p class="card-text text-justify">
                  Think elderly means immobility and no fun? Well think again because aging doesn’t
                  limit travel options in any way! Whether it’s a short staycation in town or a full
                  bore trip across continents; vacations can be enjoyable and trouble-free if
                  planned correctly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog8' }">
                <img
                  src="/imgc/blog/8/car_travel_homepage.webp"
                  alt="An Air and Land Travel Boom is on the way"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog8' }" class="link-primary">
                  <h5 class="card-title">An Air and Land Travel Boom is on the way!</h5>
                </router-link>
                <p class="card-text text-justify">
                  Statistically speaking, air travelers are growing at an ever-increasing rate! As a
                  result of land travel, the number of people is also increasing at an accelerated
                  rate and will continue to do so for many years... The majority of leisure
                  travelers in the United States travel by airplane at least once a year before the
                  pandemic.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog7' }">
                <img
                  src="/imgc/blog/7/header_homepage.jpg"
                  alt="How to be a Good Traveller? Your Best travel apps for the trip"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog7' }" class="link-primary">
                  <h5 class="card-title">
                    How to be a Good Traveller? Your Best travel apps for the trip
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  Travel apps are your best friend when travelling to a strange place alone.
                  Tourists like to fly abroad on their own but it's always nice to have some kind of
                  knowledge about where you're going and what you'll find there when you get there.
                  In this blog, we'll try to show you some of the best travel apps that need to be
                  on your smartphone while travelling to any given place. Let's check it out !
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog6' }">
                <img
                  src="/imgc/blog/6/header.webp"
                  alt="Can you use westjet companion flight for vacation packages"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog6' }" class="link-primary">
                  <h5 class="card-title">
                    Can you use westjet companion flight for vacation packages?
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  Travel within Canada is something any Canadian resident dreams of. They are always
                  looking out for the cheapest and the most comfortable way of travelling. And why
                  not? Canada is a huge country and travelling from one place to the other can cost
                  a lot and take a lot of time. The most convenient way of travel is by air.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog5' }">
                <img
                  src="/imgc/blog/5/header_homepage.jpg"
                  alt="International Parcel Shipping – Tips for overseas shipments"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog5' }" class="link-primary">
                  <h5 class="card-title">
                    International Parcel Shipping – Tips for overseas shipments
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  If you are living abroad and have friends and family in America, or if you're
                  planning on moving abroad, then this article is for you. Each country has its own
                  customs laws that must be followed by the exporter and the importer. If these
                  rules aren't followed, there could be serious consequences for both parties
                  involved which could lead to issues with payment settling and even shipment
                  termination.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog1' }">
                <img
                  src="/imgc/blog/1/header_homepage.jpg"
                  alt="The future of travel"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog1' }" class="link-primary">
                  <h5 class="card-title">The future of travel!</h5>
                </router-link>
                <p class="card-text text-justify">
                  Here has never been a year when understanding the future of travel has been more
                  important. Which countries can you go to without quarantining? Do I have to take a
                  Covid-19 test during my journey?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog2' }">
                <img
                  src="/imgc/blog/2/header_homepage.jpg"
                  alt="Having a flight companion means more than just company"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog2' }" class="link-primary">
                  <h5 class="card-title">Having a flight companion means more than just company</h5>
                </router-link>
                <p class="card-text text-justify">
                  For the most part, flying in the U.S. is pretty safe. In fact, it’s the safest way
                  to travel. But it’s always a good idea to travel with someone in case something
                  happens. Luckily, you can fly with a buddy if you can.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog3' }">
                <img
                  src="/imgc/blog/3/header.webp"
                  alt="India Announces Air Bubbles With US, France Flights have started"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog3' }" class="link-primary">
                  <h5 class="card-title">
                    India Announces ‘Air Bubbles’ With US, France; Flights have started
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  In a major development in India’s civil aviation sector, Union Aviation Minister
                  Hardeep Puri has announced that India has established ‘air bubbles’ with France
                  and the US.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'Blog3' }">
                <img
                  src="/imgc/blog/4/header_homepage.jpg"
                  alt="India Announces Air Bubbles With US, France Flights have started"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link :to="{ name: 'Blog4' }" class="link-primary">
                  <h5 class="card-title">
                    Travel anxiety and how a travel companion can help ease your travel anxiety
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  Travel anxiety is an uncomfortable feeling that one gets when traveling. It
                  usually occurs during travel, especially on long car rides or flights. Travel
                  anxiety is a very normal thing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data: function () {
    return {
      blogs: [],
    };
  },
  async created() {
    document.title = 'Trepr - Blogs';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'If you want to know more about earning while travel or looking for a travel companionship or travel news or sending packages; we have it all covered.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );

    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;

    await fetch(apiUrl + 'blogs/get-all', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$data.blogs = resData.blogs;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style scoped></style>
